.button__inner {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: 15px;
   grid-row-gap: 10px;
   margin-bottom: 20px;
}

.button__item {
   border-radius: 5px;
   padding: 21px;
   display: flex;
   justify-content: center;
   gap: 3px;
   align-items: center;
   font-family: inherit;
   color: inherit;
   font-size: inherit;
   line-height: inherit;
   margin: 0;
   background: #8A8A8A;
   cursor: pointer;
   transition: .2s;
   outline: none;
   border: none;
   text-decoration: none;
}

.button__item:hover {
   transform: scale(.95);
}

.blue {
   background: #4A4E69;
}

.pink {
   background: #C9ADA7;
}

.save {
   background: #686DE0;
}

.download {
   background: #FF7979;
}

@media (max-width: 545px) {
   .button__inner {
      grid-template-columns: 1fr;
   }
}
