.dreams {
   margin-bottom: 127px;
   position: relative;
}

.trectangle {
   position: absolute;
   top: 50%;
   right: -50px;
   transform: translateY(-50%);
}

.dreams__title {
   font-weight: 700;
   font-size: 42px;
   line-height: 51px;
   text-transform: uppercase;
   color: #DFF9FB;
   text-align: center;
   margin-bottom: 50px;
}

.dreams__title b {
   color: #C23616;
}

.dreams__inner {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   justify-items: center;
   gap: 45px;
}

.dreams__img {
   max-width: 100%;
   display: block;
   border-radius: 5px;
}

.dreams__btn {
   padding: 27px 82px;
   display: block;
   width: fit-content;
   margin: 60px auto 18px;
   box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.25);
   border-radius: 50px;
}

.dreams__subtext {
   text-align: center;
   font-size: 20px;
   line-height: 24px;
}

@media (max-width: 630px) {
   .dreams__inner {
      grid-template-columns: 1fr 1fr;
   }
}

@media (max-width: 530px) {
   .dreams__title {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 20px;
   }
}

@media (max-width: 620px) {
   .dreams__inner {
      gap: 20px;
   }
}

@media (max-width: 420px) {
   .dreams__inner {
      grid-template-columns: 1fr;
   }
   .dreams__btn {
      padding: 20px 40px;
   }
}