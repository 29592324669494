.modal__inner {
   background: #252525;
   box-shadow: 0px 0px 100px 5px rgba(255, 255, 255, 0.25);
   border-radius: 20px;
   padding: 52px 75px;
   position: relative;
   color: #fff;
   max-width: 1165px;
   width: 100%;
}

.modal__close {
   background: none;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
   position: absolute;
   right: 15px;
   top: 15px;
   z-index: 3;
}

.modal__close:hover {
   transform: scale(.9);
}

.modal__username {
   display: flex;
   align-items: center;
   gap: 6px;
}

.modal__auth-mob {
   margin-bottom: 14px;
}

.modal__result {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 51px;
}

.modal__result-info {
   height: 100%;
   display: flex;
   flex-direction: column;
}

.modal__result-infolist {
   display: grid;
   grid-template-columns: 1fr 2fr;
   grid-column-gap: 65px;
   grid-row-gap: 15px;
   font-size: 18px;
   line-height: 22px;
   margin-top: auto;
}

.modal__result-infolist-item {
   display: flex;
   flex-direction: column;
}

.modal__result-infolist-label {
   color: #717171;
}

.modal__result-info-promt-title {
   color: #717171;
   font-size: 25px;
   line-height: 30px;
   margin-bottom: 4px;
}

.modal__result-info-promt-text {
   font-weight: 700;
}

.modal__result-info-promt {
   margin-bottom: 30px;
}

.modal__result-info-remain {
   font-size: 25px;
   line-height: 30px;
   color: #949494;
   margin-bottom: 30px;
}

.modal__result-info-remain b {
   color: #fff;
}

.modal__result-imgwrapper {
   display: flex;
   align-items: center;
   align-items: center;
   max-width: 512px;
   max-height: 512px;
   position: relative;
}

.modal__result-imgblur {
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   backdrop-filter: blur(15px);
   gap: 15px;
}

.modal-result-img {
   object-fit: contain;
   max-width: 100%;
}

.modal__download {
   display: flex;
   align-items: center;
   gap: 6px;
   font-family: inherit;
   font-size: inherit;
   color: inherit;
   line-height: inherit;
   border: none;
   outline: none;
   margin: 0;
   padding: 0;
   margin-top: 14px;
   transition: .2s;
   background: none;
   cursor: pointer;
   text-decoration: none;
   width: fit-content;
}

.modal__download:hover {
   transform: scale(.95);
}

.modal__result-imgblurplan {
   padding: 27px;
   background: linear-gradient(180deg, #ED213A 0%, #93291E 100%);
   border-radius: 20px;
   max-width: 70%;
   width: 100%;
   text-align: center;
   text-decoration: none;
   color: inherit;
   transition: .2s;
}

.modal__result-imgblurplan:hover {
   transform: scale(.95);
}

.modal__result-imgblurlink {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 13px;
   max-width: 70%;
   width: 100%;
   gap: 23px;
   background: linear-gradient(180deg, #396AFC 0%, #2948FF 78.12%);
   border-radius: 20px;
   text-decoration: none;
   color: inherit;
   transition: .2s;
}

.modal__result-imgblurlink:hover {
   transform: scale(.95);
}

.modal__download-mob {
   display: none;
}

@media (max-width: 1200px) {
   .modal__result {
      grid-template-columns: 1fr 1.5fr;
   }

   .modal__inner {
      max-width: 900px;
   }
}

@media (max-width: 940px) {
   .modal__result {
      grid-template-columns: 1fr;
   }

   .modal__inner {
      margin: 20px;
      padding: 40px;
      max-height: calc(100vh - 40px);
      overflow-y: scroll;
   }
   
   .modal__result-imgwrapper {
      margin: 0 auto;
   }

   .modal__inner::-webkit-scrollbar {
      width: 8px;
   }
   
   .modal__inner::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 15px;
   }

   .modal__download {
      display: none;
   }

   .modal__download-mob {
      display: flex;
      margin-top: 0;
   }

   .modal__auth-mob {
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: center;
   }
}

@media (max-width: 545px) {
   .modal__result-infolist {
      grid-template-columns: 1fr 1fr;
   }

   .modal__result-info-remain {
      font-size: 22px;
   }
}

@media (max-width: 500px) {
   .modal__result-infolist {
      grid-template-columns: 1fr;
   }

   .modal__result-infolist-item {
      flex-direction: row;
      justify-content: space-between;
   }

}

@media (max-width: 470px) {
   .modal__close {
      top: 10px;
      right: 10px;
   }

   .modal__auth-mob {
      flex-direction: column;
      gap: 10px;
   }
}

@media (max-width: 450px) {
   .modal__close img {
      max-width: 40px;
   }  
}

@media (max-width: 425px) {
   .modal__inner {
      padding: 25px;
   }
}

@media (max-width: 400px) {
   .modal__result-infolist-item {
      flex-direction: column;
      gap: 5px;
   }
}