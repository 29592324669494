.advantage {
   margin-bottom: 127px;
}

.advantage__inner {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 60px;
}

.advantage__num {
   display: flex;
   margin-bottom: 26px;
   font-weight: 700;
   font-size: 35px;
   line-height: 43px;
   max-width: 60px;
   width: 100%;
   height: 60px;
   background: #2E2B48;
   border-radius: 50%;
   align-items: center;
   justify-content: center;
}

.advantage__text {
   font-size: 25px;
   line-height: 42px;
}

@media (max-width: 1100px) {
   .advantage__inner {
      grid-template-columns: 1fr;
   }

   .advantage__num {
      margin: 0 auto 26px;
   }

   .advantage__text {
      text-align: center;
   }
}

@media (max-width: 600px) {
   .advantage__text {
      font-size: 22px;
      line-height: 32px;
   }
}