.pricing__faq-title {
   font-size: 40px;
   line-height: 49px;
   font-weight: 700;
   font-size: 40px;
   line-height: 49px;
   text-align: center;
   margin-bottom: 33px;
}

.pricing__faq {
   padding-top: 146px;
}

.pricing__faq-btn {
   padding: 20px 73px;
   background: linear-gradient(180deg, #ED213A 0%, #93291E 100%);
   border-radius: 40px;
   color: #fff;
   text-decoration: none;
   font-size: 30px;
   line-height: 37px;
   transition: .2s;
   width: fit-content;
   margin: 0 auto;
   display: block;
   margin-top: 50px;
}

.pricing__faq-btn:hover {
   transform: scale(.95);  
}

@media (max-width: 560px) {
   .pricing__faq-title {
      font-size: 35px;
      line-height: 39px;
   }
}

@media (max-width: 470px) {
   .pricing__faq-btn {
      font-size: 25px;
      padding: 15px 40px;
   }
}

@media (max-width: 420px) {
   .pricing__faq-title {
      font-size: 30px;
      line-height: 34px;
   }
}