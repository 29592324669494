.loader__inner {
   position: fixed;
   bottom: 15px;
   right: 15px;
   background: #fff;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
   border-radius: 20px;
   padding: 44px 15px;
   display: flex;
   gap: 20px;
   align-items: center;
   color: #000;
   z-index: 999;
}

.loader__text {
   max-width: 290px;
}

.loader__img {
   flex: 0 0 auto;
   animation: rotate infinite 1s;
   transform-origin: center center;
}

@keyframes rotate {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

@media (max-width: 620px) {
   .loader__inner {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 150px;
   }
}