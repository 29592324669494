.tryforfree {
   position: relative;
   margin-bottom: 127px;
}

.trectangle {
   position: absolute;
   right: -50px;
   bottom: 20%;
}

.tryforfree__wrapper {
   padding: 89px 83px 66px;
   background-color: #0D093A;
   box-shadow: 0px 0px 6px 10px rgba(255, 255, 255, 0.15);

   /* filter: blur(5px) ; */
   border-radius: 10px;
}

.tryforfree__inner {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 70px;
   margin-bottom: 45px;
}

.tryforfree__title {
   font-weight: 700;
   font-size: 40px;
   line-height: 49px;
   text-transform: uppercase;
   color: #DFF9FB;
   text-align: center;
   margin-bottom: 35px;
}

.tryforfree__title b {
   color: #C23616;
}

.tryforfree__btn {
   display: flex;
   gap: 23px;
   align-items: center;
   background: linear-gradient(180deg, #396AFC 0%, #2948FF 78.12%);
   border-radius: 20px;
   text-decoration: none;
   width: fit-content;
   padding: 13px 28px;
   font-weight: 400;
   font-size: 20px;
   line-height: 24px;
   color: #FFFFFF;
   text-decoration: none;
   margin: 0 auto;
}

.tryforfree__item {
   max-width: 100%;
}

.tryforfree__item img {
   max-width: 100%;
}

@media (max-width: 1100px) {
   .tryforfree__inner {
      gap: 30px;
   }
}

@media (max-width: 1000px) {
   .tryforfree__inner {
      gap: 30px;
      grid-template-columns: 1fr;
      justify-items: center;
   }

   .tryforfree__wrapper {
      max-width: fit-content;
      margin: 0 auto;
   }
}

@media (max-width: 530px) {
   .tryforfree__inner {
      gap: 30px;
      grid-template-columns: 1fr;
      justify-items: center;
   }

   .tryforfree__wrapper {
      max-width: fit-content;
      margin: 0 auto;
      padding: 45px 40px 35px;
   }

   .tryforfree__title {
      font-size: 32px;
      line-height: 36px;
   }
}