.howitworks {
   margin-bottom: 70px;
}

.howitworks__title {
   font-weight: 700;
   font-size: 43px;
   line-height: 52px;
   text-transform: uppercase;
   color: #DFF9FB;
   text-align: center;
   margin-bottom: 60px;
}

.howitworks__title b {
   color: #C23616;
}

.howitworks__text {
   text-align: center;
   font-size: 25px;
   line-height: 44px;
}

@media (max-width: 600px) {
   .howitworks__text {
      font-size: 22px;
      line-height: 32px;
   }
}

@media (max-width: 530px) {
   .howitworks__text {
      font-size: 20px;
      line-height: 26px;
   }

   .howitworks__title {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 20px;
   }
}