.result {
   padding: 47px 92px 66px;
   background: rgba(216, 216, 216, 0.15);
   box-shadow: 0px 0px 30px 5px rgba(255, 255, 255, 0.25);
   border-radius: 5px;
}

.result__text {
   text-align: center;
   font-size: 30px;
   line-height: 37px;
   margin-bottom: 25px;
}

.result__link {
   font-family: inherit;
   background: none;
   border: none;
   outline: none;
   cursor: pointer;
   font-size: inherit;
   color: #fff;
   margin: 0;
   padding: 0;
}

.result__img {
   width: 100%;
   display: block;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   max-width: 100%;
}

.canvas__button-item {
   margin-top: 32px;
   padding: 33px 20px;
   font-family: inherit;
   color: inherit;
   font-size: 30px;
   line-height: 37px;
   border-radius: 50px;
   font-weight: 700;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
   background: linear-gradient(180deg, #45A247 0%, #283C86 100%);
   box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.25);
   width: 100%;
   display: block;
}

.canvas__button-item:hover {
   transform: scale(.95);
}

.result__buttons {
   display: flex;
   justify-content: center;
   align-items: center;
}


.result__save {
   margin: 0;
   padding: 0;
   font-family: inherit;
   color: inherit;
   padding: 10px 36px;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
   font-size: inherit;
   background: none;
   border: 1px solid rgba(0, 0, 0, 0.2);
   background: #fff;
   color: #000;
}

.result__save:hover {
   transform: scale(.95);
}

.result__download {
   margin: 0;
   padding: 0;
   font-family: inherit;
   color: inherit;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
   display: flex;
   align-items: center;
   justify-content: center;
   background: none;
   transition: .2s;
}

.result__download:hover {
   transform: scale(.95);
}

.result__saves {
   display: flex;
   align-items: center;
   gap: 14px;
   justify-content: flex-end;
}

.imagegenerate__text {
   text-align: center;
   margin-top: 50px;
   font-size: 18px;
   line-height: 22px;
}

.imagegenerate__text-link {
   color: #266DD8;
   text-decoration: none;
}

@media (max-width: 570px) {
   .result__text {
      font-size: 25px;
      line-height: 29px;
   }
}

@media (max-width: 500px) {
   .result__text {
      font-size: 22px;
      line-height: 26px;
   }
}

@media (max-width: 400px) {
   .result__text {
      font-size: 20px;
      line-height: 24px;
   }
}

@media (max-width: 1050px) {
   .result__empty {
      display: none;
   }

   .canvas__button-item {
      width: 100%;
   }
}

@media (max-width: 880px) {
   .result {
      padding: 40px;
   }
}

@media (max-width: 760px) {
   .result__buttons {
      grid-template-columns: 1fr;
      justify-items: center;
      gap: 20px;
   }

   .canvas__button-item {
      font-size: 25px;
      padding: 20px 40px;
      width: fit-content;
   }
}

@media (max-width: 500px) {
   .result {
      padding: 40px 20px;
   }
}

@media (max-width: 390px) {
   .canvas__button-item {
      font-size: 22px;
      padding: 15px 30px;
      width: fit-content;
   }
}