.videosection {
   position: relative;
   margin-bottom: 127px;
}

.trectangle {
   position: absolute;
   left: -50px;
   bottom: 10%;
}

.videosection__inner {
   display: flex;
   gap: 45px;
}

.reversed {
   flex-direction: row-reverse;
}

.videosection__info-title {
   font-weight: 700;
   font-size: 43px;
   line-height: 44px;
   text-transform: uppercase;
   margin-bottom: 15px;
}

.videosection__info-title b {
   color: #C23616;
}

.videosection__info-text {
   font-size: 25px;
   line-height: 40px;
   margin-bottom: 25px;
}

.videosection__info-list {
   list-style: none;
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.videosection__info-list-item {
   padding-left: 30px;
   font-size: 25px;
}

.videosection__video {
   box-shadow: 0px 0px 30px 10px rgba(255, 255, 255, 0.15);
   border-radius: 5px;
   overflow: hidden;
   flex: 0 0 auto;
   max-width: 600px;
   width: 100%;
}

@media (max-width: 1250px) {
   .videosection__inner {
      flex-direction: column;
   }

   .videosection__video {
      margin: 0 auto;
      height: 300px;
   }
}

@media (max-width: 600px) {
   .videosection__info-text, .videosection__info-list-item {
      font-size: 22px;
      line-height: 32px;
   }
}

@media (max-width: 560px) {
   .videosection__video {
      height: 200px;
   }
}

@media (max-width: 530px) {
   .videosection__info-title {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 20px;
   }
}