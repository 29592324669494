.modal__wrapper {
   backdrop-filter: blur(2px);
   background: rgba(0, 0, 0, 0.8);
   position: fixed;
   height: 100%;
   width: 100%;
   left: 0;
   top: 0;
   z-index: 3;
   display: flex;
   align-items: center;
   justify-content: center;
   
}

.modal  {
   width: 100%;
   display: flex;
   justify-content: center;
}

@media (max-width: 940px) {
   .modal__wrapper {
      /* height: auto; */
   }
}