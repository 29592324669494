.saved {
   padding: 65px 0px 100px;
}

.container {
   max-width: 1485px;
   padding: 0px 15px;
   width: 100%;
   margin: 0 auto;
}

.saved__toggle {
   max-width: 570px;
   display: grid;
   grid-template-columns: 1fr 1fr;
   justify-content: center;
   margin: 0 auto 51px;
   background: #D9D9D9;
   border-radius: 10px;
   position: relative;
}

.saved__active-btn {
   position: absolute;
   width: 50%;
   height: 100%;
   top: 0;
   background: #C4252E;
   border-radius: 10px;
   transition: .2s;
}

.saved__active-btn.left {
   left: 0;
   transform: translateX(0%);
}

.saved__active-btn.right {
   left: 100%;
   transform: translateX(-100%);
}

.saved__toggle-btn {
   font-size: 30px;
   line-height: 37px;
   color: #4E4E4E;
   font-family: inherit;
   background: none;
   border: none;
   outline: none;
   padding: 25px 20px;
   font-weight: 700;
   cursor: pointer;
   transition: .2s;
}

.saved__toggle-btn.active {
   color: #fff;
}

.saved__toggle-btn span {
   position: relative;
   z-index: 2;
}

.saved__loadmore {
   padding: 25px 140px;
   background: linear-gradient(180deg, #ED213A 0%, #93291E 100%);
   box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.25);
   border-radius: 50px;
   color: #fff;
   font-size: 30px;
   line-height: 37px;
   border: none;
   outline: none;
   font-family: inherit;
   cursor: pointer;
   margin: 0 auto;
   width: fit-content;
   display: block;
   transition: .2s;
}

.saved__loadmore:hover {
   transform: scale(.95);
}

@media (max-width: 670px) {
   .saved__toggle-btn {
      font-size: 25px;
      padding: 20px;
   }

   .saved__loadmore {
      font-size: 25px;
      padding: 25px 50px;
   }
}

@media (max-width: 490px) {
   .saved__toggle {
      grid-template-columns: 1fr;
   }

   .saved__active-btn {
      width: 100%;
      height: 50%;
   }

   .saved__active-btn.left {
      top: 0;
      left: 0;
      transform: translateY(0%);
   }
   
   .saved__active-btn.right {
      top: 100%;
      left: 0;
      transform: translateY(-100%);
   }
}