.topsection {
   padding: 170px 0px 220px;
}

.main__title {
   font-size: 55px;
   line-height: 67px;
   text-align: center;
   text-transform: uppercase;
   color: #DFF9FB;
   margin-bottom: 42px;
}

.main__title b {
   color: #C23616;
}

.main__text {
   font-style: normal;
   font-weight: 400;
   font-size: 25px;
   line-height: 40px;
   text-align: center;
   max-width: 800px;
   margin: 0 auto 102px;
}

.container {
   max-width: 1170px;
   margin: 0 auto;
   width: 100%;
   padding: 0px 15px;
}

@media (max-width: 920px) {
   .topsection {
      padding: 60px 0px;
   }

   .main__title {
      font-size: 40px;
      line-height: 48px;
   }

   .main__text {
      font-size: 22px;
      line-height: 30px;
   }
}

@media (max-width: 600px) {

   .main__title {
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 30px;
   }

   .main__text {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 50px;
   }
}

@media (max-width: 550px) {

   .main__title {
      font-size: 26px;
      line-height: 30px;
   }

   .main__text {
      font-size: 18px;
      line-height: 22px;
   }
}