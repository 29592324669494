.thebest {
   position: relative;
}

.trectangle {
   position: absolute;
   top: 50%;
   right: -50px;
   transform: translateY(-50%);
}

.thebest__title {
   font-weight: 700;
   font-size: 42px;
   line-height: 51px;
   text-transform: uppercase;
   color: #DFF9FB;
   margin-bottom: 40px;
   text-align: center;
}

.thebest__title b {
   color: #419450;
}

.thebest__inner {
   display: grid;
   grid-template-columns: 1fr 1fr;
   row-gap: 40px;
   column-gap: 60px;
   justify-items: center;
   max-width: 960px;
   width: 100%;
   margin: 0 auto 78px;
}

.thebest__img {
   max-width: 100%;
   display: block;
} 

.thebest__subtitle {
   font-size: 42px;
   line-height: 51px;
   text-transform: uppercase;
}

.btn {
   font-weight: 700;
   font-size: 20px;
   line-height: 24px;
   padding: 27px 82px;
   color: #FFFFFF;
   text-decoration: none;
   display: block;
   background: linear-gradient(180deg, #45A247 0%, #283C86 100%);
   box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.25);
   border-radius: 40px;
   width: fit-content;
   margin: 0 auto 18px;
}

.subtext {
   text-align: center;
}

@media (max-width: 620px) {
   .thebest__inner {
      gap: 20px;
   }
}

@media (max-width: 550px) {
   .thebest__subtitle {
      font-size: 32px;
      line-height: 36px;
   }
}

@media (max-width: 530px) {
   .thebest__subtitle {
      font-size: 32px;
      line-height: 36px;
   }
}

@media (max-width: 420px) {
   .thebest__subtitle {
      font-size: 24px;
      line-height: 28px;
   }

   .btn {
      padding: 20px 40px;
   }
}