.button {
   background: linear-gradient(180deg, #ED213A 0%, #93291E 100%);;
   border-radius: 50px;
   outline: none;
   border: none;
   color: #fff;
   font-weight: 700;
   transition: .2s;
   cursor: pointer;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
}

.button:hover {
   transform: scale(1.05);
}