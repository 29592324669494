.faq-row-wrapper {
   background-color: transparent !important;
}

.faq-body section.faq-row {
   background-color: #fff;
}

.faq-body section.faq-row .row-title {
   padding: 15px 0px !important;
}

.faq-body section.faq-row {
   padding: 21px 50px !important;
   border-radius: 10px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   padding: 0;
   margin-bottom: 13px;
   border-bottom: none;
}

.faq-body section.faq-row:last-child {
   padding: 0;
   margin-bottom: 0px;
}

.faq-body section.faq-row .icon-wrapper {
   top: 36px !important;
   max-width: 35px !important;
   max-height: 35px !important;
}

.row-title-text {
   font-size: 30px;
   line-height: 37px;
}

@media (max-width: 560px) {
   .row-title-text {
      font-size: 25px;
   }

   .faq-body section.faq-row {
      padding: 10px 30px !important;
   }
}