.header__inner {
   max-width: 1610px;
   margin: 0 auto;
   width: 100%;
   padding: 30px 15px;
}

.header__menu {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.header__menu-left {
   display: flex;
   gap: 32px;
   align-items: center;
}

.header__menu-inner {
   display: flex;
   gap: 32px;
   align-items: center;
}

.header__menu-link {
   color: inherit;
   text-decoration: none;
   position: relative;
}

.header__menu-link::after {
   content: '';
   position: absolute;
   width: 0%;
   bottom: -5px;
   height: 2px;
   background: #000;
   left: 0;
   transition: .2s;
}

.header__menu-link:hover::after {
   width: 100%;
}

.header__menu-right {
   display: flex;
   align-items: center;
   gap: 32px;
}

.header__menu-logout {
   background: none;
   color: inherit;
   text-decoration: none;
   position: relative;
   font-size: inherit;
   font-family: inherit;
   outline: none;
   border: none;
   padding: 0px;
   cursor: pointer;
}

.header__menu-logout::after {
   content: '';
   position: absolute;
   width: 0%;
   bottom: -5px;
   height: 2px;
   background: #000;
   left: 0;
   transition: .2s;
}

.header__menu-logout:hover::after {
   width: 100%;
}

.header__menu-mobbtn {
   display: flex;
   width: 50px;
   height: 30px;
   flex-direction: column;
   justify-content: space-between;
   cursor: pointer;
   border: none;
   background: none;
   margin: 0;
   padding: 0;
}

.header__menu-mobbtn span {
   height: 4px;
   border-radius: 15px;
   background-color: #fff;
   width: 100%;
}