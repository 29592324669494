.canvas__inner {
   padding: 47px 92px 66px;
   background: rgba(216, 216, 216, 0.15);
   box-shadow: 0px 0px 30px 5px rgba(255, 255, 255, 0.25);
   border-radius: 5px;
}

.preview__image-item-img {
   max-width: 100%;
}

.preview__inner {
   display: flex;
   align-items: center;
   justify-content: center;
}

.canvas__text {
   margin-bottom: 35px;
   text-align: center;
}

.canvas__instruction {
   font-size: 30px;
   line-height: 37px;
   margin-bottom: 4px;
   font-weight: 700;
}

.canvas__link {
   text-decoration: none;
   color: #fff;
}

.canvas__button {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 26px;
   margin: 30px 0px 20px;
}

.canvas__button-item {
   padding: 33px 20px;
   font-family: inherit;
   color: inherit;
   font-size: 30px;
   line-height: 37px;
   border-radius: 50px;
   font-weight: 700;
   border: none;
   outline: none;
   cursor: pointer;
   transition: .2s;
}

.canvas__button-item:hover {
   transform: scale(.95);
}

.canvas__button-item--undress {
   background: linear-gradient(180deg, #45A247 0%, #283C86 100%);
}

.canvas__button-item--cancel {
   background: linear-gradient(180deg, #ED213A 0%, #93291E 100%);
}

.imagegenerate__text {
   text-align: center;
   margin-top: 50px;
   font-size: 18px;
   line-height: 22px;
}

.imagegenerate__text-link {
   color: #266DD8;
   text-decoration: none;
}

@media (max-width: 880px) {
   .canvas__inner {
      padding: 40px;
   }
}

@media (max-width: 570px) {
   .canvas__button {
      grid-template-columns: 1fr;
   }
}

@media (max-width: 570px) {
   .canvas__instruction {
      font-size: 25px;
      line-height: 29px;
   }

   .canvas__button-item {
      padding: 20px 40px;
   }
}

@media (max-width: 500px) {
   .canvas__instruction {
      font-size: 22px;
      line-height: 26px;
   }
   .canvas__inner {
      padding: 40px 20px;
   }
}

@media (max-width: 400px) {
   .canvas__instruction {
      font-size: 20px;
      line-height: 24px;
   }
}