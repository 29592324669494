.instruction {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   max-width: 1000px;
   width: 100%;
   grid-gap: 47px;
   margin: 40px auto 0px;
}

.instruction__image {
   max-width: 100%;
}

.instruction__head {
   background: #F7F7F7;
   width: 100%;
   display: block;
   padding: 12px;
   text-align: center;
   border-bottom: 2px solid #EDEDED;
}

.instruction__item {
   display: flex;
   flex-direction: column;
   background: rgba(255, 255, 255, 0.1);
   border-radius: 5px;
}

.instruction__body {
   padding: 0px 20px 35px;
   display: flex;
   flex-direction: column;
}

.instruction__text {
   font-size: 18px;
}

.instruction__image-wrapper {
   padding: 31px 0px;
   height: 100%;
}

.instruction__image {
   display: block;
}

@media (max-width: 1000px) {
   .instruction {
      grid-template-columns: 1fr;
   }

   .instruction__item {
      max-width: 420px;
      margin: 0 auto;
   }
}