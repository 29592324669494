.dropzone {
   background: rgba(216, 216, 216, 0.15);
   box-shadow: 0px 0px 30px 5px rgba(255, 255, 255, 0.25);
   border-radius: 5px;
   max-width: 685px;
   margin: 0 auto;
   padding: 81px 84px 58px;
   transition: .2s;
   margin-bottom: 35px;
}

.dropzone.dropzone__active {
   border: 6px dashed #919191;
}

.dropzone__btn {
   font-weight: 400;
   font-size: 25px;
   line-height: 30px;
   background: linear-gradient(180deg, #45A247 0%, #283C86 100%);
   border-radius: 40px;
   padding: 24px;
   display: block;
   width: 100%;
   color: #fff;
   font-family: inherit;
   border: none;
   cursor: pointer;
   transition: .2s;
   max-width: 360px;
   margin: 0 auto 35px;
}

.dropzone__btn:hover {
   transform: scale(.95);
}

.dropzone__texts {
   text-align: center;
}

.dropzone__texts-drop {
   font-size: 25px;
   line-height: 30px;
   margin-bottom: 6px;
}

@media (max-width: 580px) {
   .dropzone {
      padding: 50px 44px;
   }
}

@media (max-width: 470px) {
   .dropzone__texts-drop {
      font-size: 22px;
   }
}

@media (max-width: 440px) {
   .dropzone {
      padding: 30px;
   }
}

@media (max-width: 400px) {
   .dropzone__texts-drop {
      font-size: 20px;
   }
}