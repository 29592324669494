.imagegenerate {
   padding: 127px 0px;
   position: relative;
}

.imagegenerate__text {
   text-align: center;
   max-width: 825px;
   width: 100%;
   margin: 0 auto;
}

.imagegenerate__text-link {
   text-decoration: none;
   color: #266DD8;
}

.trectangle {
   position: absolute;
   top: -10%;
   left: -50px;
   z-index: -2;
}

@media (max-width: 500px) {
   .imagegenerate__text {
      font-size: 20px;
   }
}

@media (max-width: 400px) {
   .imagegenerate__text {
      font-size: 18px;
   }
}